<template>
  <div class="wrapper">
    <section class="carousel-section" v-show="current_page == 'politics'">
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-ride="carousel"
      >
        <div class="carousel-inner"></div>

        <a
          class="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </section>
    <div class="">
      <div v-for="(c, index) in outrightData" v-bind:key="getCountryKey(index)">
        <div
          class="market-header d-flex justify-content-between p-2"
          data-toggle="collapse"
          v-bind:data-target="'#collapseCategory' + index"
          aria-expanded="false"
          v-bind:aria-controls="'collapseCategory' + index"
        >
          <div v-text="c.category_name"></div>
          <div class="text-light">
            <i class="bi bi-chevron-down"></i>
          </div>
        </div>

        <div
          class="markets-odd-section collapse show"
          v-bind:id="'collapseCategory' + index"
        >
          <div class="p-0 m-0">
            <Tournament
              v-bind:leagues="c.tournaments"
              v-bind:category_name="c.category_name"
              v-bind:sport_id="sport_id"
              v-bind:load="isLoading(c.country_code)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const Tournament = () => import("@/components/outright/Tournament.vue");
import axios from "@/services/api";

export default {
  name: "Outright",
  components: {
    Tournament,
  },
  data: function () {
    return {
      sub_page: "",
      loading: [],
      visible: [],
      is_busy: false,
      current_page: "",
      busy: false,
      sports: [],
      countries: [],
      outrights: [],
      mqttClient: false,
      dates: [],
      sport_name: "Sports",
      selected_hour: 0,
      hours: [-1, 1, 3, 6, 12, 24, 48],
      sport_id: 1,
    };
  },
  mounted() {
    this.resetFilters();

    // this.reloadProfile();
    // this.getOutright();

    var d0 = this.daysFromNow(0);
    var d1 = this.daysFromNow(1);
    var d2 = this.daysFromNow(2);
    //var d3 = this.daysFromNow(3);
    //var d4 = this.daysFromNow(4);

    this.dates.push({
      date: d0,
      label: this.getDayOfWeek(d0),
    });

    this.dates.push({
      date: d1,
      label: this.getDayOfWeek(d1),
    });

    this.dates.push({
      date: d2,
      label: this.getDayOfWeek(d2),
    });

    /*
    this.dates.push({
      date: d3,
      label: this.getDayOfWeek(d3)
    });


    this.dates.push({
      date: d4,
      label: this.getDayOfWeek(d4)
    });
     */
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        // react to route changes...
        // document.title = to.params.name.replace(/[^a-z0-9+]+/gi, " ");
        // document.description = "The best boosted odds for " + to.params.name.replace(/[^a-z0-9+]+/gi, " ") + " get free bonus, freebets and many cashback offers ";
        var type = to.params.type; //this.baseConverter(to.params.game_id, 36, 10);
        var sport_id = 0;
        if (type == "formula-1") {
          sport_id = 40;
          this.getOutright(sport_id);
          this.$store.dispatch("setCurrentPage", "formula1");
          this.current_page = "formula-1";
        }
        if (type == "politics") {
          sport_id = 18;
          this.getOutright(sport_id);
          this.$store.dispatch("setCurrentPage", "politics");
          this.current_page = "politics";
        }

        // this.live = to.params.status === 'live';
        // this.getMatch();
      },
    },
  },
  computed: {
    betslip_count: function () {
      return this.$store.state.outrightbetslip.total;
    },
    current_sub_page: function () {
      return this.$store.state.current_sub_page;
    },
    // current_page: function () {

    //   return this.$store.state.current_page;
    // },

    market_id: function () {
      return this.$store.state.market_id;
    },
    hour: function () {
      return -1;
      // this.$store.state.hour;
    },
    date: function () {
      return this.$store.state.upcoming_date;
    },
    outrightData: function () {
      var vm = this;
      var d = [];

      vm.jQuery.each(vm.outrights, function (k, v) {
        if (parseInt(v.sport_id) === vm.sport_id) {
          vm.jQuery.each(v.categories, function (x, y) {
            if (y.tournaments.length > 0) {
              d.push(y);
            }
          });
        }
      });

      return d;
    },
  },
  methods: {
    isActive: function (sport) {
      if (this.sport_id == sport.sport_id) {
        return " active";
      } else {
        return "";
      }
    },
    getKey: function (index) {
      return Math.random()
        .toString(20)
        .replace("0.", "random-id-" + index + "-");
    },

    setHour: function (h) {
      this.selected_hour = h;
      this.$store.dispatch("setHour", h);
    },
    humanHourName: function (h) {
      if (h === -1) {
        return "All Time";
      }

      return h === "" || h === "" || h === 0 ? "Time" : h + " Hours";
    },

    getOutright: function (sport_id) {
      var vm = this;
      vm.outrights = [];
      var path = process.env.VUE_APP_URL_OUTRIGHT + "/" + sport_id;

      vm.busy = true;
      axios
        .post(path, JSON.stringify({}))
        .then((res) => {
          vm.busy = false;

          vm.outrights = res.data.message;

          vm.sports = [];

          vm.jQuery.each(vm.outrights, function (k, v) {
            vm.sports.push({
              sport_id: v.sport_id,
              sport_name: v.sport_name,
            });
            vm.setSport({
              sport_id: v.sport_id,
              sport_name: v.sport_name,
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    isLoading: function (index) {
      const i = this.loading.indexOf(index);
      if (i > -1) {
        return true;
      }

      return false;
    },
    selectedClass: function (index) {
      const i = this.visible.indexOf(index);
      if (i > -1) {
        return "selected";
      }

      return "";
    },
    isVisisble: function (index) {
      const i = this.visible.indexOf(index);
      if (i > -1) {
        return true;
      }
      return false;
    },
    loadData: function (index) {
      const i = this.loading.indexOf(index);

      if (i > -1) {
        // index already exisits in loading array

        // toggle visibility
        const i = this.visible.indexOf(index);

        // check if its visible
        if (i > -1) {
          this.visible.splice(i, 1); // hide visibility
        } else {
          this.visible.push(index); // else make visible
        }
      } else {
        this.loading.push(index);
        // make visible
        this.visible.push(index);
      }
    },
    getCountryKey: function (index) {
      var prefix = "outrights-" + index;
      return Math.random()
        .toString(10)
        .replace("0.", "sport-id-" + prefix + "-");
    },
    setSport: function (sport) {
      this.sport_name = sport.sport_name;
      this.sport_id = sport.sport_id;
    },
    geChildPageActiveClass: function (childpage) {
      return childpage === this.$store.state.upcoming_date
        ? "selected-date"
        : "";
    },
    setUpcomingDate: function (date) {
      this.$store.dispatch("setUpcomingDate", date);
    },
    setUpcomingAll: function () {
      this.$store.dispatch("setUpcomingDate", "");
    },
    getDayName: function (i, label) {
      console.log("index ==> " + i + " label ==> " + label);

      if (i === 0) {
        return "Today";
      }

      if (i === 1) {
        return "Tomorrow";
      }

      return label;
    },
  },
};
</script>
